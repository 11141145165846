<template>
  <div class="hello-ezuikit-js">
    <div id="video-container" style="width:100%;height:8.5rem"></div>
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
import {postVideoToken} from "@/api/api";

export default {
  name: "HelloEzuikit",
  props: {
    setObj: {
      type: Object,
      default: () => ({
        type: 'voice',
        url: 'ezopen://open.ys7.com/G04996581/1.live',
      })
    },
  },
  watch: {
    setObj: {
      deep: true,
      immediate: true,
      handler: function () {
        this.handlePlay()
      }
    },
  },
  data() {
    return {
      token: 'at.51pb9gy2amtk0e602lyrypigdcpiz2q0-4u9lc5sqcg-0gq1aef-f3na5efr5',
      player: null,
    }
  },
  mounted: () => {

  },
  created() {
    this.getToken()
    setTimeout(() => {
      this.handlePlay();
    }, 500);
  },
  methods: {
    getToken() {
      const body = {
        appkey: "0d88df523a624d62b654750c7502dfed",
        appSecret: "8acc0db1016ce1d6c358488e4d2b392f"
      }
      postVideoToken(body).then(res => {
        if (res.data.code != 200) {
          setTimeout(() => {
            this.getToken();
          }, 5000);
          return;
        }
        this.token = res.data.result
        console.log('!!!!!!!!!!!!!!!!!!!!!!!!')
        console.log(res.data.result)
        setTimeout(() => {
          this.handlePlay();
        }, 500);
      });
    },
    handlePlay() {
      if (this.player) {
        document.getElementById('video-container').innerHTML = "";
      }
      this.player = new EZUIKit.EZUIKitPlayer({
        autoplay: true,
        id: 'video-container',
        accessToken: this.token,
        url: this.setObj.url,
        template: this.setObj.type, // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
        // 视频上方头部控件
        header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
        plugin: ['talk'],                       // 加载插件，talk-对讲
        // 视频下方底部控件
        footer: ['talk', 'broadcast', 'hd',], // 如果template参数不为simple,该字段将被覆盖
        audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
      });
    },
  },

};
</script>
